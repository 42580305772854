import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {

    set_as_read(e){
       let id = e.target.dataset.id
       let count_notice = document.getElementById('count_notices');
       let notice_container = document.getElementById('notice-card-body');
        if(id === undefined){
            let element = e.target.parentNode;
            id = element.dataset.id;
        }

        $.ajax({
            url: `${location.origin}/trader/notice/${id}/read`,
            data: {
                format: "json"
            },
            type: 'GET',
            success: function (data){
                 if(data.success){
                    let element_to_remove = document.getElementById(`notice_${id}`);
                    let separator_to_remove = document.getElementById(`separator_${id}`);
                    element_to_remove.remove();
                     separator_to_remove.remove();
                    let calc = parseInt(count_notice.innerText) - 1;
                    if(calc === 0){
                        count_notice.innerText = '';
                        count_notice.classList.add('bg-secondary');
                        count_notice.classList.remove('bg-red');
                        notice_container.innerHTML = `
                            <div class="item-notice">
                                <a href="#" class="">No hay noticias para mostrar</a>
                            </div>
                        `;

                    }else{
                        count_notice.innerText = calc;
                    }
                 }
            }
        })
    }
}