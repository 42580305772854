import { Controller } from "stimulus"
import $ from 'jquery';
import toastr from 'toastr'


let clean = () => {
    let form = $('#contact_form');
    let input = form.find('input');
    let textarea = form.find('textarea');

    input.map(elem => {
        if(input.hasOwnProperty(elem)){
            input[elem].value = '';
        }
    });
    textarea.map(elem => {
        if(textarea.hasOwnProperty(elem)){
            textarea[elem].value = '';
        }
    });

    return true;

}

let sendMail = (data) => new Promise((resolve, reject) => {
    data['format'] = 'json'
    console.log(data);

    $.ajax({
        url: location.origin+'/new_contact',
        data: data,
        type: 'POST',
        success: function (result) {
            resolve(result);
        }
    })
})

export default class extends Controller {
    initialize(){
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": true,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "3000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }

    }
    connect() {
       $('#contact_form_submit').on('click',function () {
          let form = $('#contact_form');
          let input = form.find('input');
          let textarea = form.find('textarea');
          let object = {};

          input.map(elem => {
             if(input.hasOwnProperty(elem)){
                 object[input[elem].name] = input[elem].value;
             }
          });
           textarea.map(elem => {
               if(textarea.hasOwnProperty(elem)){
                   object[textarea[elem].name] = textarea[elem].value;
               }
           });


           sendMail(object).then(
               result => {
                   if(result.success){
                       toastr.success(result.msg,result.head);
                       clean();
                   }else{
                       toastr.options = {
                           "closeButton": false,
                           "debug": false,
                           "newestOnTop": false,
                           "progressBar": true,
                           "positionClass": "toast-bottom-right",
                           "preventDuplicates": true,
                           "onclick": null,
                           "showDuration": "300",
                           "hideDuration": "1000",
                           "timeOut": "5000",
                           "extendedTimeOut": "1000",
                           "showEasing": "swing",
                           "hideEasing": "linear",
                           "showMethod": "fadeIn",
                           "hideMethod": "fadeOut"
                       }

                       toastr.error(result.msg,result.head);
                   }
               }
           )
       })
    }

}