import { Controller } from "stimulus"
import $ from 'jquery';
require('block-ui/jquery.blockUI')
export default class extends Controller {
    connect() {
    }

    updateSrc(e){
        e.preventDefault();
        let url = this.data.get('url');
        let data = {id: this.data.get('id')};
        let error_str = this.data.get('error');
        data['card_holder_name'] = $("#id_card_holder_name").val();
        data['card_holder_last_name'] = $("#id_card_holder_lats_name").val();
        data['card_holder_email'] = $("#id_card_holder_email").val();
        jQuery.ajax({
            async: true,
            evalScripts: true,
            type: "POST",
            contentType: "application/x-www-form-urlencoded",
            url: this.data.get('mark_redsys_transaction_used'),
            data: data,
            success: function () {
                $('#gatewaymini').attr('src', url);
                $('#confirm_form').css('display','none');
                $('#request_data_ok_message').css('display','none');
                $('#payment_form').css('display','block');
            },
            error: function () {
                alert(error_str);
                $('#confirm_form').css('display', 'block');
                $('#request_data_ok_message').css('display', 'block');
            }
        });
    };
}