/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//require("@rails/ujs").start()
//require("turbolinks").start()
// import '@fortawesome/fontawesome-free-solid'
//require("imports-loader");

//CSS
import '../stylesheets/application'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/css/v4-shims.css'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import "trix/dist/trix.css";
import  'material-design-iconic-font/dist/css/material-design-iconic-font.min.css'
// require("@rails/ujs").start();
// require("turbolinks").start();
var Trix  = require("trix");
require("@rails/actiontext");
require("@rails/activestorage").start();

require("channels");
// require("jquery-ui-dist/jquery-ui");
// var jQuery = require("jquery");

import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
require('bootstrap/dist/js/bootstrap');
import "controllers"
import toast from "bootstrap/js/src/toast";
window.toastr = toast;
import Turbolinks from 'turbolinks'
import Vue from 'vue/dist/vue.esm';
import VueResource from 'vue-resource';
import VuejsDialog from "vuejs-dialog"
import VueSanitize from "vue-sanitize"
import Loading from 'vue-loading-overlay';
import noActionTable from './components/noActionTable.vue'
import basicTable from './components/basicTable.vue'
import basicOrderedTable from './components/basicOrderedTable.vue'
import claimTable from './components/claimTable.vue'
import PaymentRequestTable from './components/paymentRequests.vue'
import userTable from "./components/userTable.vue";
import CurrencyDetails from './components/adminComponentsDetails/currencyDetails.vue'
import DestinationDetails from './components/adminComponentsDetails/destinationDetails.vue'
import HotelPlansDetails from './components/adminComponentsDetails/hotelPlansDetails.vue'
import PmsConnectionsDetails from './components/adminComponentsDetails/pmsConetionsDetails.vue'
import MerchantTranslateDetails from './components/adminComponentsDetails/merchantTranslateDetails.vue'
import AcquiresDetails from './components/adminComponentsDetails/acquiresDetails.vue'
import NoticeDetails from './components/adminComponentsDetails/noticeDetails.vue'
import ClaimDetails from './components/adminComponentsDetails/claimDetails.vue'
import FaqDetails from './components/adminComponentsDetails/faqDetails.vue'

Turbolinks.start();

Vue.use(VueResource);
Vue.use(VuejsDialog);
Vue.use(VueSanitize);
