import {Controller} from "stimulus"
import $ from 'jquery';
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

export default class extends Controller {
    connect () {
    }

    resendNotification (e) {
        e.preventDefault ();
        e.stopPropagation ();
        let text = e.target.dataset.text;
        let element = e;
        let title = e.target.dataset.titlemsg;
        let url = undefined;

        if (e.target.dataset.hasOwnProperty ('url')) {
            url = e.target.dataset.url;
        }

        if (text === undefined) {
            element = e.target.parentNode.parentElement;
            text = element.dataset.text
            title = element.dataset.titlemsg;

            if (text === undefined) {
                element = element.parentNode;
                text = element.dataset.text
                title = element.dataset.titlemsg;
            }
        }

        Swal.fire ({
            icon: 'question',
            title: title,
            text: text,
            showCancelButton: true,
            backdrop: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: title,
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'btn btn-style mr-2',
                cancelButton: 'btn btn-secondary btn-payment'
            },
            buttonsStyling: false
        })
            .then ((result) => {


                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    let target = '';
                    if (element.href === undefined) {
                        if (url === undefined) {
                            let originalTarget = element.originalTarget;
                            target = originalTarget.href;
                        } else {
                            target = location.origin + url;
                        }

                    } else {
                        target = element.href;
                    }

                    location.href = target;
                    return true;
                }
            })
    }

    ask_for_send (e) {
        e.preventDefault ();
        e.stopPropagation ();
        let text = e.target.dataset.text;
        let title = e.target.dataset.title;
        let element = e;
        let url = e.target.href
        let method = e.target.dataset.method;

        if (text === undefined) {
            element = e.target.parentNode.parentElement;
            text = element.dataset.text
            title = element.dataset.title;
            url = element.href;
            method = element.dataset.method;

            if (text === undefined) {
                element = element.parentNode;
                text = element.dataset.text
                title = element.dataset.title;
                url = element.href;
                method = element.dataset.method;
            }
        }


        Swal.fire ({
            icon: 'question',
            title: title,
            text: text,
            showCancelButton: true,
            backdrop: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: title,
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'btn btn-style mr-2',
                cancelButton: 'btn btn-secondary btn-payment'
            },
            buttonsStyling: false
        })
            .then ((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                   $.ajax({
                       url: url,
                       data:{},
                       type: method,
                       success: function (data) {
                          location.reload();
                       }
                   })

                }
            })
    }
}