import {Controller} from "stimulus"
import $ from 'jquery';
import {createPopper} from '@popperjs/core'


const popperInstance2 = (obj,text) => new Promise((resolve, reject) =>{
        resolve( createPopper(obj, text, {
            placement: "auto",
            strategy: "absolute",
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [4, 4],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        padding: 10,
                    },
                },
                {
                    name: 'arrow',
                    options: {
                        padding: 5, // 5px from the edges of the popper
                    },
                },
            ],

        }))
    } )
;

function show(obj, instance) {
    obj.setAttribute('data-show', '');
    instance.update();
}

function hide(obj) {
    obj.removeAttribute('data-show');
}

export default class extends Controller {

    connect(){

    }

    tooltip(event){
        let element = document.querySelector('#'+event.target.dataset.tooltip);

        popperInstance2(event.target,element)
            .then( result => {
                if(event.type === 'touchstart'){
                    show(element, result);
                }
                if(event.type === 'touchend'){
                    hide(element);
                }
                if(event.type === 'mouseenter'){
                    show(element, result);
                }
                if(event.type === 'mouseleave'){
                    hide(element);
                }

            });

    }

}