import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import reservationContract from "select2"
require('block-ui/jquery.blockUI')
export default class extends Controller {
    static targets = [ "input", "hideme" ];
    connect() {
        this.validate();
    }

    validate(){
        if ( document.querySelector('#card_holder_form')!= null ){
            function validate_card_holder() {
                let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                if (regex.test($("#id_card_holder_email").val()) != false &&
                    $("#id_card_holder_name").val().length &&
                    $("#id_card_holder_lats_name").val().length &&
                    $('#request_data_ok').checked
                ){
                    jQuery('#bank_transfer_indications').css('display','block');
                    jQuery('#continue_button').prop('disabled', false);
                    jQuery('#continue_button_button').prop('disabled', false);
                }else {
                    jQuery('#continue_button').prop('disabled', true);
                    jQuery('#continue_button_button').prop('disabled', true);
                    jQuery('#bank_transfer_indications').css('display', 'none');
                }
            }
            jQuery('#id_card_holder_name, #id_card_holder_lats_name, #id_card_holder_email').on('input', function (e) {
                validate_card_holder();
            })
            jQuery('#request_data_ok').on('click', function (e) {
                var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                if (this.checked &&
                    regex.test($("#id_card_holder_email").val()) != false &&
                    $("#id_card_holder_name").val().length &&
                    $("#id_card_holder_lats_name").val().length
                ){
                    jQuery('#bank_transfer_indications').css('display','block');
                    jQuery('#continue_button').prop('disabled', false);
                    jQuery('#continue_button_button').prop('disabled', false);
                }else {
                    jQuery('#continue_button').prop('disabled', true);
                    jQuery('#continue_button_button').prop('disabled', true);
                    jQuery('#bank_transfer_indications').css('display', 'none');
                }
            });
        }else{
            jQuery('#request_data_ok').on('click', function (e) {
                if (this.checked) {
                    jQuery('#bank_transfer_indications').css('display','block');
                    jQuery('#continue_button').prop('disabled', false);
                    jQuery('#continue_button_button').prop('disabled', false);
                }else {
                    jQuery('#continue_button').prop('disabled', true);
                    jQuery('#continue_button_button').prop('disabled', true);
                    jQuery('#bank_transfer_indications').css('display', 'none');
                }
            });
        }
    }
}